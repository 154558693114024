<template>
  <div
    :class="[
      'field-title',
      {
        'field-title--viewed': isEntityViewed,
        'field-title--locked': isEntityLocked,
        'field-title--has-status-selector': showStatusSelector,
      },
    ]"
  >
    <div
      v-if="hasIcon"
      class="field-title__icon"
    >
      <field-locked-reason
        v-if="isEntityLocked"
        :entity="entity"
      />
      <slot
        v-else
        name="icon"
      >
        <svg-icon
          v-if="useDefaultIcon"
          :name="icon"
        />
        <field-last-updated
          v-else-if="isShowLastUpdates"
          :entity="entity"
        />
      </slot>
    </div>

    <div class="field-title__body">
      <div class="field-title__title">
        <span
          v-if="showTitleAsText"
          class="field-title__text"
        >
          <slot
            name="title"
            :title="title"
            >{{ title }}</slot
          >
        </span>
        <b-link
          v-else
          class="field-title__text field-title__text--link"
          :to="entity.getLinkTo()"
          data-cy="entity-link"
          @click.native="onGoToEntity"
        >
          <slot
            name="title"
            :title="title"
            >{{ title }}</slot
          >
        </b-link>

        <div
          v-if="showStatusSelector"
          class="field-title__status"
        >
          <slot name="status-selector">
            <field-status :entity="entity" />
          </slot>
        </div>
      </div>

      <div
        v-if="isShowSubtitle"
        class="field-title__subtitle"
      >
        <slot name="subtitle">
          {{ subtitle }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import MixinSlots from '@/mixins/MixinSlots.vue';

const FieldStatus = () => import('@/components/entity-fields/FieldStatus.vue');
const FieldLastUpdated = () => import('@/components/entity-fields/FieldLastUpdated');
const FieldLockedReason = () => import('@/components/entity-fields/FieldLockedReason');

export default {
  name: 'field-title',
  components: {
    FieldLastUpdated,
    FieldStatus,
    FieldLockedReason,
  },
  mixins: [MixinSlots],
  props: {
    entity: { type: Object, default: Object },
    useDefaultIcon: { type: Boolean, default: false },
    isShowSubtitle: { type: Boolean, default: false },
    isShowLastUpdates: { type: Boolean, default: false },
    showTitleAsText: { type: Boolean, default: false },
    showStatusSelector: { type: Boolean, default: false },
  },
  inject: {
    onFieldTitleLinkClick: { default: null },
  },
  computed: {
    icon() {
      return this.entity.getProperty('smallIcon');
    },
    title() {
      return this.entity.getName();
    },
    subtitle() {
      return this.entity.getValue('subtitle');
    },
    isEntityViewed() {
      return this.entity.getValue('viewed');
    },
    isEntityLocked() {
      return typeof this.entity.isLocked === 'function' ? this.entity.isLocked() : false;
    },
    hasIcon() {
      return this.hasSlot('icon') || this.isShowLastUpdates || this.useDefaultIcon || this.isEntityLocked;
    },
  },
  methods: {
    onGoToEntity() {
      if (typeof this.onFieldTitleLinkClick === 'function') {
        this.onFieldTitleLinkClick(this.entity);
      }

      this.$emit('goto', this.entity);

      if (!this.entity.listInstance) {
        return;
      }

      this.entity.listInstance.onGoToEntity(this.entity.id);
    },
  },
};
</script>

<style lang="scss">
.field-title {
  $block: &;

  --title-color: var(--entity-title-color);
  --title-hover-color: var(--entity-title-hover-color);
  --visited-title-color: var(--entity-title-visited-color);

  display: flex;

  &__icon {
    color: var(--title-color);
    margin-right: 5px;

    .print-details & {
      color: var(--color-text-main);
      font-weight: normal;
    }
  }

  &__text {
    color: var(--title-color);
    display: inline-block;
    font-weight: bold;

    .print-details & {
      color: var(--color-text-main);
      font-weight: normal;
    }

    &--link:hover {
      color: var(--title-hover-color);

      text-decoration: none;
    }
  }

  &__subtitle {
    color: var(--title-color);
  }

  .entity-list:not(.is-user-section) &__text--link:visited,
  &--viewed &__text--link {
    color: var(--visited-title-color);

    &:hover {
      color: var(--title-hover-color);
    }
  }

  &--locked {
    --title-color: var(--locked-entity-title-color);
    --visited-title-color: var(--locked-entity-title-visited-color);

    #{$block}__title {
      font-style: italic;

      .print-details & {
        font-style: normal;
      }
    }
  }

  &__title {
    display: flex;
  }

  &__status {
    margin-left: 8px;
  }

  &__icon .svg-icon {
    --size: 14px;

    .print-details & {
      --size: 20px;
    }
  }
}
</style>

<template>
  <div class="field-user">
    <avatar
      v-if="!hideAvatar"
      :user-data="userInfo"
      class="field-user__avatar"
    />

    <div class="field-user__name">
      <div
        v-if="userInfo.isDeleted"
        class="field-user__delete-badge c-badge c-badge--warning"
      >
        {{ $vDict('users.status_suspended.text') }}
      </div>

      <b-link
        v-if="isShowLink"
        class="user-info"
        :to="userInstance.getLinkTo()"
        @click.native="userInstance.linkToHandler($event)"
      >
        <div class="user-info__name">{{ userInfo.name }}</div>
        <div
          v-if="userInfo.email"
          class="user-info__email"
        >
          {{ userInfo.email }}
        </div>
      </b-link>

      <div
        v-else
        class="user-info"
      >
        <div class="user-info__name">{{ userInfo.name }}</div>
        <div
          v-if="userInfo.email"
          class="user-info__email"
        >
          {{ userInfo.email }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar';
import { User } from '@/entities';

export default {
  name: 'field-user',
  components: {
    Avatar,
  },
  props: {
    userData: { type: Object, default: Object },
    hideAvatar: { type: Boolean, default: false },
    showEmail: { type: Boolean, default: false },
    user: { type: Object, default: Object },
  },
  computed: {
    isShowLink() {
      return this.$store.getters['Account/hasComponent']('all_users');
    },
    userInstance() {
      return this.user?.type === 'entity' ? this.user : new User(this.userData);
    },
    userInfo() {
      return {
        id: this.userInstance,
        name: this.userInstance.getName(),
        email: this.showEmail && typeof this.userInstance.getEmail === 'function' ? this.userInstance?.getEmail() : '',
        isDeleted: typeof this.userInstance.isDeleted === 'function' ? !!this.userInstance.isDeleted() : false,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.field-user {
  display: flex;

  &__avatar {
    margin-right: 10px;
  }
}

.user-info {
  $block: &;

  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--text-color);

  &:is(a):hover {
    color: var(--link-hover-color);
  }

  &__email {
    font-size: 12px;
    line-height: 14px;
    color: var(--text-shaded);
    transition: color 0.3s ease-in-out;

    a#{$block}:hover & {
      color: var(--link-hover-color);
    }
  }
}
</style>
